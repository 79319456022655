<template>
  <div
    v-if="isAuthLoading"
    class="flex items-center justify-center w-full py-24 h-screen sm:py-28 md:py-36 lg:py-52"
  >
    <base-svg
      class="h-4 w-4 mr-1 text-primary-red inline-block"
      src="icons/circleSpinner.svg"
      tag="span"
    />
    Please wait ...
  </div>

  <div v-else-if="isAuthError">
    <div class="bg-no-repeat fixed bg-cover inset-0 -z-10 bg-marvel-login-background" />
    <div class="bg-custom-gray-12 text-custom-gray-13 relative rounded border border-solid h-auto mr-auto ml-auto mb-2 login-inner-width mt-24">
      <div class="pt-11 pl-0 pr-0 pb-11 relative border-b solid z-10">
        <h1>
          <img
            :src="require('@/assets/images/logo-v2.png')"
            class="block my-0 mx-auto max-w-xs max-h-12"
            alt="Marvel Entertainment External Tenant logo logo"
          >
        </h1>
      </div>
      <div class="py-5 px-10">
        <base-button
          tag="a"
          href="javascript:void(0)"
          class="p-4 block text-center"
          variant="btn-blue"
          @click="handleRedirectToLogout()"
        >
          <template #default>
            <span>
              TRY AGAIN
            </span>
          </template>
        </base-button>
      </div>
      <div class="py-5 px-10 text-primary-red font-semibold text-md">
        {{ `${(errMessage || '')}`.replaceAll('Error: ','') }}
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, defineAsyncComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import okta from '@/lib/okta.js';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';
// import useToastNotifications from '@/hooks/toastNotifications.js';

export default {
    name: 'LoginRedirect',

    components: {
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSvg
    },

    setup () {
        const router = useRouter();
        const store = useStore();
        // const { notificationsStack } = useToastNotifications();

        const isAuthLoading = ref(false);
        const isAuthError = ref(false);
        const errMessage = ref('');

        // onMounted(async () => {
        // });

        const fetchUserPreferences = async () => {
            try {
                await store.dispatch('users/fetchUserPreferences');
            } catch (err) {
                console.error(err);
            }
        };

        const handleOktaRedirect = async () => {
            try {
                isAuthLoading.value = true;
                const oktaTokens = await okta.fetchOktaTokens();
                const userInfo = await okta.fetchUserInfo(oktaTokens.accessToken, oktaTokens.idToken);
                const userInfoPayload = {
                    ...userInfo,
                    oktaAccessToken: oktaTokens.accessToken,
                    oktaIdToken: oktaTokens.idToken,
                    token: oktaTokens.accessToken.accessToken
                };
                await store.dispatch('auth/loginUser', { userInfo: userInfoPayload });
                await fetchUserPreferences();
                router.push({ name: 'PortalSelection' });
            } catch (err) {
                console.error(err);
                isAuthError.value = true;
                errMessage.value = err;

                // notificationsStack.value.push({
                //     type: ERROR,
                //     message: `${err}`
                // })
                // setTimeout(async () => { await store.dispatch('auth/logoutUser') }, 6500);
            } finally {
                isAuthLoading.value = false;
            }
        };

        const handleRedirectToLogout = async () => {
        };

        onMounted(() => {
            handleOktaRedirect();
        });

        return {
            isAuthLoading,
            isAuthError,
            errMessage,
            handleRedirectToLogout
        };
    }
};
</script>
<style scoped>
.login-inner-width {
  width: 100%;
  max-width: 740px;
}
</style>
